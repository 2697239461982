.search_container {
    .scroller::-webkit-scrollbar-thumb,
    .scroller::-webkit-scrollbar-track,
    .scroller::-webkit-scrollbar{
        display: none;
    }

    .search_form {
        background-color: var(--background-color-2);
        box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.06);
        border-radius: var(--border-radius-12);
        padding: 15px;
        margin-bottom: 20px;
        .search_block {
            width: 52%;
            @media screen and (max-width: $small-desktop) {
                width: 40%;
            }
            @media screen and (max-width: $tablet) {
                width: 30%;
            }        
            @media screen and (max-width: $small-tablet) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .btn {
            padding: 14px 20px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            border-radius: var(--border-radius-8);      
            @media screen and (max-width: $small-tablet) {
                width: 100%;
                margin-bottom: 5x;
            }
            &.btn-notification {
                display: flex;
                align-items: center;
                padding: 12px 20px;
                svg {
                    margin-right: 12px;
                }
                &:hover {
                   svg path {
                        fill: var(--text-color-2);
                    }
                }
            }
        }
    }
          
    .search_title {
        font-weight: 900;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 5px;
    }

    .search_result {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: var(--text-color-4);
        display: block;
        margin-top: 10px;
        .active {
            color: var(--brand-color-2);
            span {
                font-weight: 700;
            }
        }
    }

    .filter_form-col {
        .home_form {
            max-width: 100%;
        }
        
        .filter-form {
            background-color: var(--background-color-2);
            border-radius: var(--border-radius-12);
            padding: 15px;
            margin-bottom: 20px;    
            border: 1px solid var(--border-color-1);
            .filter_form-item {
                .search_block {
                    position: relative;
                    .search_input {
                        padding-right: 40px;
                    }
                    .search_icon {
                        position: absolute;
                        right: 12px;
                        bottom: 14px;
                    }
                }
                .search_label {
                    font-family: 'roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: var(--text-color-1);
                    margin-bottom: 10px;
                    display: inline-block;
                }
                &.search-item {
                    padding-bottom: 15px;
                    position: relative;
                    margin-bottom: 15px;
                    width: 100%;
                    &:after {
                        content: '';
                        height: 1px;
                        background-color: var(--border-color-1);
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: -15px;
                        right: -15px;
                    }
                }
                &:not(.search-item) {                
                    border-bottom: 1px solid var(--border-color-1);
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                  
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border: 0;
                    }
                    .custom-select  {
                        .user_container {
                            box-shadow: none;
                            border-radius: 0;
                            padding: 0;
                        }
                    }
                    .drop-down-section {
                        position: unset;
                        box-shadow: none;
                        padding: 0;
                        overflow-y: auto;
                        border-radius: 0;
                        .drop-down-section_button {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 8px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            .public-text {                               
                                color: var(--text-color-3);
                            }
                        }
                    
                    }
                }
                .link-primary {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
        }
        
    }

}

@mixin border-radius($radius:5px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}
@mixin transition($style, $duration, $type, $delay:0s) {
  transition: $style $duration $type $delay;
  -webkit-transition: $style $duration $type $delay;
  -moz-transition: $style $duration $type $delay;
  -o-transition: $style $duration $type $delay;
}
@mixin placeholder( $color ){
  &::placeholder { /* Firefox */
    color: $color;
    opacity: 1;     
  }
  &::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
  }
  &:-moz-placeholder { /* WebKit browsers */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder { /* WebKit browsers */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { 	/* Internet Explorer 10+ */
    color: $color;
  }
}
@mixin transform-rotate($deg:-90deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
}
@mixin transform-rotateY($deg:-90deg) {
  transform: rotateY($deg);
  -webkit-transform: rotateY($deg);
  -moz-transform: rotateY($deg);
  -o-transform: rotateY($deg);
  -ms-transform: rotateY($deg);
}
@mixin transform-scale($deg:1) {
  transform: scale($deg);
  -webkit-transform: scale($deg);
  -moz-transform: scale($deg);
  -o-transform: scale($deg);
  -ms-transform: scale($deg);
}
@mixin box-shadow($shadow...) {
  -webkit-box-shadow:$shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin translateY($degree) {
  transform: translateY($degree);
  -webkit-transform: translateY($degree);
  -moz-transform: translateY($degree);
  -o-transform: translateY($degree);
  -ms-transform: translateY($degree);
}

.container {
    max-width: 1188px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
}

.content_item_container,
.search_container {
    min-height: 100vh;
    padding-top: 114px;
    padding-bottom: 80px;
    font-family: 'Roboto';
    font-style: normal;
}

.form-row {
    &.search-row ,
    &.content-row {
        margin-right: -8px;
        margin-left: -8px;
        .col {
            padding-left: 8px;
            padding-right: 8px;
        }
        
        @media screen and (max-width: $tablet) {
            flex-direction: column;
        }
    }

    &.content-row {        
        @media screen and (max-width: $tablet) {
            flex-direction: column-reverse;
        }
    }

    .filter_form-col,
    .citations-col {
        flex: 0 0 26%;
        max-width: 26%;
        @media screen and (max-width: $tablet) {
           margin-bottom: 20px;
        }
    }

    .content_form-col,
    .content-col {  
        flex: 0 0 74%;
        max-width: 74%;  
    }

    .filter_form-col,
    .content_form-col,
    .content-col,
    .citations-col { 

        @media screen and (max-width: $tablet) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.about {
    background-color:  #f1f4f8;
    min-height: 100vh;
    padding-top: 164px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &_container {
        background-color: #ffffff;
        border: 1px solid #E2E8F0;
        border-radius: 12px;
        padding: 40px;
        box-sizing: border-box;
        max-width: 940px;
        margin-bottom: 76px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
    }

    &_title {
        color: #018197;
        font-size: 36;
        font-family: "roboto";
        margin-bottom: 20px;
    }

    &_text {
        color: #2D3748;
        font-size: 16;
        margin-bottom: 22px;
        font-family: "roboto";
        &:nth-last-child(1){
            margin-bottom: 0px;
        }
    }
}
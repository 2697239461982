.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);

   & > img {
    width: 30%!important;
    height: auto!important;
  }
}

@media only screen and (max-width: 500px) {
  .preloader {
    & > span, & > img {
      width: 100%!important;
      height: auto!important;
    }
  }
}
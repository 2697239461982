.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f1f4f8;  
  width: 100%;

  &_content {
    display: flex;
    width: 100%;
    margin: 24px auto 24px;
    justify-content: space-between;
  }

  &_item {
    display: inline-block;
  }

  &_logo {
    color: #A0AEC0;
    font-family: "roboto";
    font-size: 36px;
  }

  &_menu {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: auto;

    &-item {
      color: #A0AEC0;
      font-family: "roboto";
      font-size: 14px;

      &:hover,
      &:active,
      &:focus {
        color: var(--text-color-1);
        text-decoration: none;
      }

    }
  }

  &-line {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: linear-gradient(270deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 53.41%, rgba(224, 225, 226, 0) 100%);
  }

  &_copyright {
    color: #A0AEC0;
    font-family: "roboto";
    font-size: 14px;
    text-align: center;
    padding: 10px 40px;
    box-sizing: border-box;
  }
}
.content {
    font-family: 'Roboto';
    font-style: normal;
    mark {
        background-color: var(--brand-color-1);
        color: var(--text-color-2);
        padding: 0 2px;
    }
    .info_item {
        background-color: var(--background-color-2);
        border-radius: var(--border-radius-12);
        padding: 15px;
        margin-bottom: 15px;    
        border: 1px solid var(--border-color-1);
        position: relative;                
        &:last-child {
            margin-bottom: 0;
        }
        .custom-input-checkbox {  
            input[type=checkbox] {
                & + .checkmark {
                    position: absolute;
                    top: -8px;
                    left: 15px;
                }
            }
        }
        .info_number {
            position: absolute;
            top: -8px;
            left: 36px;
            display: block;
            height: 16px;
            min-width: 16px;
            border-radius: var(--border-radius-4);
            background-color: var(--brand-color-1);
            color: var(--text-color-2);
            text-align: center;
            line-height: 16px;
            font-weight: 700;
            font-size: 12px;
            padding: 0 3px;
        }
        
        .info_title  {                   
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;                    
            a {                    
                color: var(--brand-color-2);
                cursor: pointer;
            }
        }
        .info_block {
            .info_author {
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: var(--brand-color-2);
                text-decoration: underline;
            }
            .info_date {
                margin: 0;
                color: var(--text-color-6);
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
            }
            .info_text {
                color: var(--text-color-5);
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .info_institution {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: var( --text-color-6);
                margin-bottom: 10px;
            }
            .info_list {
                &--item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 4px;
                    color: var(--text-color-3);
                    span.info_value {
                        font-weight: 700;
                        color: var(--text-color-1);
                    }
                    &.email-address {
                        span.info_value a {
                            color: var(--brand-color-2);
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .btn-info {
            font-size: 14px;
            line-height: 16px;
        }
        
    }
    .content_filter {
        padding-left: 21px;
        padding-right: 5px;
        margin-bottom: 20px;  
        .info_count {
            color: var(--text-color-1);
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            margin: 0 15px 0 5px;
        }  
        .btn-info {
            color: var(--text-color-1);
            font-weight: 600;
            text-transform: uppercase;
        }
        .content_publication-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 19px;
            color: var(--text-color-7);
            p {
                margin: 0;
            }
            p:first-child {
                font-size: 16px;
                color: var(--text-color-1);
            }

        }
        .content_showing-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-color-1);
        }
        .content_filter-item {
            .custom-select {
                position: relative;
                .user_container {
                    padding: 9px 15px;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: uppercase;
                    border-radius: var(--border-radius-8);
                    color: var(--text-color-1);
                    border: 1px solid var(--brand-color-1);
                    background-color: var(--background-color-3);
                    font-weight: 600;
                    white-space: nowrap;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img, svg {
                        margin-left: 10px;
                        &.select-close {
                            transform: rotate(180deg);
                            cursor: pointer;
                            min-width: 12px;
                        }
                    }
                }
                .drop-down-section {
                    max-height: 0;
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    top: 38px;
                    background-color: var(--background-color-3);
                    
                    box-sizing: border-box;
                    width: 100%;
                    box-shadow: 0px 2px 6px rgba(90, 90, 90 , 0.26);
                    border-radius: 12px;
                    justify-content: center;
                    @include transition(all, 0.15s, ease-in-out);
                    &.section-open {
                        max-height: 300px;
                        z-index: 2;
                    }
                    .drop-down-section_button {
                        padding: 5px 20px;
                        margin: 0;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        cursor: pointer;
                        &:hover {
                            background-color: var(--background-color-2);
                        }
                        &:first-child {
                            padding-top: 10px;
                        }
                        &:last-child {
                            padding-bottom: 10px;
                        }
                    }
                }
            } 
        }
    }
    .content_pagination {
        margin-top: 24px;
    }
}

.content_item_container {
    .content  {
        .content_pagination {
            margin-top: 0;
            margin-left: 40px;
        }
        .content_filter {
            padding-left: 0;
            padding-right: 0;
            
            .btn-education {
                padding: 5px;
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .back-to-link {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: var(--text-color-1);
            background-color: transparent;
            svg {
                transform: rotate(90deg);
            }
        }
        .info_item {
            .info_title {
                font-weight: 900;
                font-size: 24px;
                line-height: 28px;
                padding: 0;
                margin-bottom: 10px;
                border: 0;
                color: var(--brand-color-2);
            }
            .info_sub-title {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: var(--text-color-1);
            }
            .info_author {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: var(--brand-color-2);
                span {
                    color: var(--text-color-1);
                }
            }
            .info_author-name {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px; 
                margin-bottom: 10px;
                color: var(--text-color-1);
            }
            .info_caption {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 10px;
                color: var(--brand-color-2);
                text-transform: uppercase;
            }
            .info_resulte {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: var(--text-color-1);
            }
            .info_list {
                margin-top: 10px;
                &--item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 4px;
                    color: var(--text-color-1);
                    span.info_value {
                        font-weight: 700;
                    }
                    &.email-address {
                        span.info_value a {
                            color: var(--brand-color-2);
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .info_block-inner {
                margin-bottom: 0;
                border: 0;
                padding-bottom: 0;
                h5 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: var(--text-color-1);
                    margin-bottom: 10px;
                }
                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: black;
                }
            }
            .link-primary {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
            }
            .info_paragraph {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: var(--text-color-1);
                margin-bottom: 10px;
            }

            &.journal_info {
                .info_title {
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 42px;
                }
                .info_caption {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    } 
}

.no-data {
    font-family: "Roboto";
    font-weight: bold;
    text-align: center;
    margin-right: 38%;
    font-size: 20px;
}
.no-data-filters {
    font-family: "Roboto";
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
}
/*texts*/
.tb--tal {
  text-align: left;
}

.tb--tar {
  text-align: right;
}

.tb--tac {
  text-align: center;
}

.tb--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*height widths*/
.f-mh-100 {
  max-height: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

/* position*/
.tb--prel {
  position: relative;
}

.tb--pabs {
  position: absolute;
}

/* flex */
.tb--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}    

.tb--flex-row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
}

.tb--flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tb--align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tb--align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tb--align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.tb--justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tb--justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tb--justify-around {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
}

.tb--justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.tb--justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.tb--center {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tb--flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tb--flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.tb--dblock {
  display: block;
}

.tb--din-block {
  display: inline-block;
}

.tb--dnone {
  display: none;
}

/* flex-grid */
.tb--flex-grid4,
.tb--flex-grid6,
.tb--flex-grid8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-shrink: 0;
}

.tb--flex-grid__item4,
.tb--flex-grid__item6,
.tb--flex-grid__item8 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 1px;
}
.tb--flex-grid4 {
  margin-right: -4px;
  margin-left: -4px;
}
.tb--flex-grid6 {
  margin-right: -6px;
  margin-left: -6px;
}

.tb--flex-grid8 {
  margin-right: -8px;
  margin-left: -8px;
}

.tb--flex-grid__item4 {
  padding-right: 4px;
  padding-left: 4px;
}
.tb--flex-grid__item6 {
  padding-right: 6px;
  padding-left: 6px;
}
.tb--flex-grid__item8 {
  padding-right: 8px;
  padding-left: 8px;
}


.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.scroller {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track,
body::-webkit-scrollbar,
.scroller::-webkit-scrollbar-thumb,
.scroller::-webkit-scrollbar-track,
.scroller::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track,
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb,
.scroller::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 5px;
}

button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
a:hover {
  text-decoration: none;
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
[class*=col] {
  padding-right: 5px;
  padding-left: 5px;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.flex-gap-10 {
  gap: 10px;
}
.flex-gap-15 {
  gap: 15px;
}
.flex-gap-20 {
  gap: 20px;
}

.ml-auto {
  margin-left: auto;
}

.mb-0 {
  margin-bottom: 0!important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.text-uppercase {
  text-transform: uppercase!important;
}
.text-capitalize {
  text-transform: capitalize!important;
}
.fw-100 {
  font-weight: 100;
}
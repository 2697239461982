@import 'abstract/_reset.scss';
@import 'abstract/_mixins.scss';
@import 'base/_colors.scss';
@import 'base/_variables.scss';
@import 'base/_flex-grid.scss';
@import 'base/_layout.scss';
@import 'base/_main.scss';
@import 'base/_components.scss';


@font-face {
  font-family: "roboto";
  font-display: swap;
  src: local("roboto"),
    url("../fonts/roboto-v27-latin-regular.eot") format("eot"),
    url("../fonts/roboto-v27-latin-regular.woff") format("woff"),
    url("../fonts/roboto-v27-latin-regular.woff2");
}

@font-face {
  font-family: "Helvetica";
  font-display: swap;
  src: local("Helvetica"),
    url("../fonts/Helvetica.eot") format("eot"),
    url("../fonts/Helvetica.woff") format("woff"),
    url("../fonts/Helvetica.woff2");
}
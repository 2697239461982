:root {
    
    --scrollbar-color: #E2E8F0;

    --background-color-1: #E5E5E5;
    --background-color-2: #ffffff;
    --background-color-3: #F1F4F8;
    --background-gradient-1: linear-gradient(283.28deg, var( --brand-color-3) 0.27%, var(--brand-color-1) 100%);
    --border-color-1: #E2E8F0;
    --border-color-2: #A2ACBA;
    --border-radius-4: 4px;
    --border-radius-6: 6px;
    --border-radius-8: 8px;
    --border-radius-12: 12px;   
    --border-radius-16: 16px;    
    --brand-color-1: #4FD1C5;
    --brand-color-2: #018197;
    --brand-color-3: #0B93AA;
    --brand-color-4: rgba(79, 209, 197, 20%);

    
    
    --text-color-1: #2D3748;
    --text-color-2: #ffffff;
    --text-color-3: #A0AEC0; 
    --text-color-4: #6C737F; 
    --text-color-5: #5E687A; 
    --text-color-6: #1B1F25; 
    --text-color-7: #000000; 
    --text-color-1-light-30:  rgba(45, 55, 72, 30%);
    --text-color-1-light-80: rgba(45, 55, 72, 80%);
    --title-color-1: #313860;
    --text-color-error: #f00;

}

body {
    background-color: var(--background-color-3)!important;
}
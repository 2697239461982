* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

ul{
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

h1,h2,h3,p {
    margin-top: 0;
    margin-bottom: 0;
}

a{
    text-decoration: none;
}

img {
    display: block
}

.displayNone{
    display: none;
}
  
.visually-hidden {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

body {
	line-height: 1;
}

button,
input {
    line-height: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
    box-sizing: border-box;
}

button,
html input[type="button"], 
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; 
    cursor: pointer; 
    overflow: visible;  
}

button[disabled],
html input[disabled] {
    cursor: default;
}

::-moz-selection {
    background: var(--brand-color-1);
    color: var(--text-color-2);
    text-shadow: none;
}

::selection {
    background: var(--brand-color-1);
    color: var(--text-color-2);
    text-shadow: none;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

:focus {
    outline: none;
}
  
input, select, textarea {
    border: none;
}
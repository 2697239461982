.date-range {
    .rs-picker {
        width: 100%;
    }
    z-index: 0;
    .rs-picker-toggle-value {
        color: #A0AEC0 !important;
        font-family: "roboto" !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 15px;
        padding: 12px 19px 13px;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .rs-picker-toggle-textbox {
        border: 0;
    }
    .rs-picker-toggle {
        height: 40px;
    }
    .rs-picker-default .rs-picker-toggle {
        z-index: 1;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E2E8F0;
        border-radius: 12px;
        padding: 10px 20px 0;
        min-height: 36px;
        box-sizing: border-box;
        outline: none;
        font-family: "roboto";
        cursor: pointer;

        input {
            font-size: 14px;
            padding: 12px 19px 13px;
            font-family: "roboto";
        }
    }
    .rs-stack-item{
            .rs-btn-close{
            svg {
                display: none !important;
            }
        }
    }

    .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
    .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
    .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
        border-color: var(--brand-color-1);
        box-shadow: none;
    }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px var(--brand-color-2);
    -webkit-box-shadow: inset 0 0 0 1px var(--brand-color-2);
    box-shadow: inset 0 0 0 1px var(--brand-color-2);
    box-shadow: inset 0 0 0 1px var(--brand-color-2);
    font-weight: 600;
}

.rs-btn-link {
    color: var(--brand-color-2);
    font-weight: 500;
    &:hover {
        color: var(--brand-color-2);
    }
}

.rs-btn-primary,
.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-btn-primary:focus, .rs-btn-primary:hover {
    background-color: var(--brand-color-2);
}

.rs-calendar-table-cell-in-range:before,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: var(--brand-color-4);
    color: var(--text-color-1);
}
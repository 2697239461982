.home {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #A0AEC0;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #A0AEC0;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #A0AEC0;
    }

    min-height: 100vh;
    padding-top: 84px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    &_main {
        width: 100%;
        max-width: 1060px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px;
    }

    &_subtitle {
        font-family: "roboto";
        line-height: 1;
        margin-bottom: 5px;
        font-size: 20px;
    }

    &_title {
        font-family: "roboto";
        font-size: 36px;
        margin-bottom: 30px;
    }

    &_form {
        max-width: 410px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;

        &_full-width {
            width: 100%;
        }

        &_inline-items {
            display: flex;
            column-gap: 10px;
        }

        .dropdown-selected {
            color: #2D3748;
            font-weight: 400;
            font-size: 14px;
        }

        .dropdown-selected-bold {
            color: #2D3748;
            font-weight: 700;
            font-size: 14px;
        }

        &-new-row-line {
            display: flex;
            column-gap: 10px;

            &-and-or-not {
                max-width: 89px;
                padding: 10px 20px;
                display: inline-flex;
            }
        }

        &-item {
            width: 100%;
            margin-bottom: 15px;

            span {
                display: inline-block;
                margin-bottom: 8px;
                font-size: 10px;
                font-family: 'Helvetica';
                color: #2d3748cc;
                font-weight: 700;
            }

            &_input {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #E2E8F0;
                border-radius: 12px;
                padding: 10px 20px;
                box-sizing: border-box;
                outline: none;
                font-family: "roboto";
                height: 40px;
            }
        }

        &-width-auto {
            width: auto;
            .home_form-item, .custom-select, .user_container {
                width: auto;
            }
        }

        &-buttons {
            display: flex;
            column-gap: 10px;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 2px solid #E2E8F0;
            &_item {
                width: 100%;
                border: 1px solid #4FD1C5;
                border-radius: 8px;
                padding: 7px 10px;
                box-sizing: border-box;
                background-color: transparent;
                font-size: 12px;
                color: #2D3748;
                font-weight: 600;
                cursor: pointer;
                font-family: "roboto";
            }
            &--search {
                display: flex;
                justify-content: flex-end;
                column-gap: 10px;
                width: 100%;
                margin-top: 15px;
            }
        }

        .custom-select {
            position: relative;
            width: 100%;
            .user_container {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #ffffff;
                padding: 10px 20px;
                box-sizing: border-box;
                width: 100%;
                box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
                font-size: 12px;
                font-family: "roboto";
    
                & > p {
                    margin-right: 10px;
                }
    
                .select-close {
                    transform: rotate(180deg);
                    cursor: pointer;
                    min-width: 12px;
                }
                
                .select-open {
                    transform: rotate(0deg);
                    cursor: pointer;
                }
            }
    
            .drop-down-section {
                max-height: 0;
                overflow: hidden;
                position: absolute;
                right: 0;
                top: 42px;
                background-color: #ffffff;
                padding: 0px 20px;
                box-sizing: border-box;
                width: 100%;
                box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
                justify-content: center;
            
                &_button {
                    &:first-child {
                        padding-top: 15px;
                    }
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            
                .logoutIcon {
                    width: 20px;
                    margin-right: 15px;
                }
            }
            
            .section-open {
                max-height: 300px;
                overflow: auto;
                z-index: 2;
            }
        }

        // Search Popup Styles
        &.search_popup  {
            position: absolute;
            background-color: var(--background-color-2);
            box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.06);
            border-radius: var(--border-radius-12);
            padding: 20px;
            z-index: 1;
            top: 104px;
            max-width: 70%;  
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 10px;
            
            .collection-item-block {
                padding: 0;
            }

            .home_form-actions-row {
                display: flex;
                border-top: 2px solid  var(--border-color-1);
                padding-top: 20px;
                margin-bottom: 3px;
                .home_form-buttons {
                    border: 0;
                    padding-bottom: 0;
                }
                .home_form-buttons--search {
                    margin-top: 0;
                }
                .home_result-buttons--clear,
                .home_result-buttons--search {
                    padding: 9px 20px;
                    line-height: 14px;
                    border-radius: var(--border-radius-16);
                }
                .home_form-buttons_item {
                    width: auto;
                    padding: 7px 20px;
                    text-transform: uppercase;
                }
            }

            .home_form-row {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-flow: row wrap;
                flex-flow: row wrap;
                justify-content: space-between;                    
            } 
            
            .w-15 {
                width: calc(15% - 5px);                
            }
            .w-50 {
                width: calc(50% - 5px);        
            }
                        
            .created-rows_item--selects.add-search-rows {                        
                display: -ms-flexbox;
                display: flex;
                -ms-flex-flow: row wrap;
                flex-flow: row wrap;
                justify-content: space-between;

                .all-fields-block {
                    width: calc(35% - 10px);
                }

                .custom-select .user_container {
                    max-width: 100%;
                }
                
                .document,
                .authors,
                .organizations,
                .journals,
                .cited-references {
                    width: calc(85% - 5px);          
                }
            }
            

            @media screen and (max-width: $mobile-tablet){
                
                max-width: 80%;

                .all-fields-block,
                .input-block {
                    width: 100%;
                }
                .created-rows_item--selects.add-search-rows {
                    display: flex;
                    .drop-options-block {
                        width: calc(25% - 5px);
                    }
                    .all-fields-block,
                    .document,
                    .authors,
                    .organizations,
                    .journals,
                    .cited-references {
                        width: calc(75% - 5px);
                    }
                }
              
            }

            .date-range .rs-picker-toggle-value {
                padding: 10px 19px 13px;
            }
           
        }
    }

    .collections-grey-underline {
        width: 100%;
        height: 2px;
        background: #E2E8F0;
        border-radius: 12px;
    }

    .col-drop-options {
        width: 25%;
    }

    .created-rows {
        .col-all-fields-home,
        .organizations,
        .cited-references {
            width: 75%;
        } 
        .created-rows_item--selects.calendar-row {
            display: block;
            [class*=col] {
                padding: 0;
            }
        }
    }
}

.documents-section {
    width: 100%;
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 80px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.06);
    column-gap: 60px;
    &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-number {
            margin-bottom: 12px;
            font-size: 32px;
                color: #4FD1C5;
            font-family: "roboto";
        }
        &-text {
            font-size: 16px;
            color: #2D3748;
            font-family: "roboto";
        }
    }
}

.partners-section {
    width: 100%;
    display: flex;
    margin-bottom: 101px;
    justify-content: center;
    .partner_item {
        height: auto;
        margin: auto;
        object-fit: contain;
    }
}

.created-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 2px solid  #E2E8F0;
    padding-top: 15px;
    box-sizing: border-box;
    
    &_item {
        position: relative;

        &--selects {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .home_form-item:nth-child(2) {
                width: 75%;
            }
        }
    }
}

.created-rows.calendar-created-rows {
    .home_form-item:nth-child(2) {
        width: 100%;
    }
}

.delete-row {
    width: 16px;
    height: 16px;
    background: #4FD1C5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    position: absolute;
    right: 0;
    top: -24px;
}

.home_result-buttons--clear {
    border: 1px solid rgba(45, 55, 72, 0.3);
    border-radius: 15px;
    background-color: transparent;
    color: rgba(45, 55, 72, 0.3);
    padding: 10px 25px;
    box-sizing: border-box;
    font-weight: 700;
    font-family: "roboto";
    font-size: 12px;
    cursor: pointer;
}

.home_result-buttons--search {
    background-color: #4FD1C5;
    border: none;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px 25px;
    box-sizing: border-box;
    font-weight: 700;
    font-family: "roboto";
    font-size: 12px;
    cursor: pointer;
}

.switch {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid #E2E8F0;
    border-radius: 12px;
    height: 38px;

    .radio-button {
        position: relative;
        z-index: 2;
        width: 50%;
        height: 30px;
        opacity: 0;
    }

    .switch__label-left {
        position: absolute;
        width: 50%;
        left: 0;
        text-align: center;
        z-index: 2;
        font-weight: 700;
        cursor: pointer;
    }

    .switch__label-right {
        position: absolute;
        width: 50%;
        left: 50%;
        text-align: center;
        z-index: 2;
        font-weight: 700;
        cursor: pointer;
    }

    &__indicator {
        width: 50%;
        height: 30px;
        position: absolute;
        top: 2px;
        left: 0;
        background: #4FD1C5;
        border-radius: 12px;
        transition: transform 600ms cubic-bezier(.02,.94,.09,.97),
                    background 300ms cubic-bezier(.17,.67,.14,1.03);
    }
        input#contactChoice1:checked ~ .switch__indicator {
        transform: translateX(1%);

    }
    input#contactChoice1:checked + label{
        color: #ffffff;
    }
        input#contactChoice2:checked ~ .switch__indicator {
        transform: translateX(99%);

    }
    input#contactChoice2:checked + label{
        color: #ffffff;
    }
}
.header {
    background-image: linear-gradient(-90deg ,#0B93AA , #4FD1C5);
    padding: 23px 0;
    box-sizing: border-box;
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
    border-radius: 0 0 16px 16px;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 24px;

  
    ul, ol {
        margin-bottom: 0;
    }
    
    @media screen and (max-width: $tablet) {
        padding: 23px;        
    }
    
    @media screen and (max-width: $small-tablet) {
        width: 100%;   
        left: 0;   
        border-radius: 0; 
    }


}

.logo {
    width: 55px;
    margin-right: 100px;
    color: #ffffff;
    font-weight: 600;
    font-family: "roboto";
}

.menu {
    &_container {
        display: flex;
        column-gap: 30px;
    }

    &_link {
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        font-family: "roboto";
        position: relative;
        padding: 0 5px;
        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: var(--background-color-2);
            left: 0;
            right: 0;
            bottom: 0;
            top: 20px;
            margin: auto;
            position: absolute;
            width: 0;
            @include transition(width, .5s, ease);
        }
        &:not(.active):hover,
        &.active,
        &:active,
        &:focus {
            text-decoration: none;
            color: var(--text-color-2);
            &::after {
                width: 100%;
            }
        }
    }
}

.lang-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.lang-line {
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    user-select: none;
}

.lang-button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    font-family: "roboto";
    cursor: pointer;
    column-gap: 5px;
    opacity: 0.5;
    &.active {
        opacity: 1;
    }
}

.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "roboto";
    height: 100vh;
    background: #f8f8f8;

    h3 {
        margin-bottom: 1rem;
    }

    button {
        padding: 0.5rem 1rem;
        font-family: "roboto";
        border: none;
        border-radius: 4px;
        background: #0B93AA;
        color: white;
        font-weight: bold;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        &:hover {
            background-color: #0063aa;
        }
    }
}
// Buttons

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled),
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

[type=button]:disabled,
[type=reset]:disabled,
[type=submit]:disabled,
button:disabled,
.btn:disabled,
.btn.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    @include transition(all, .15s, ease-in-out);
}

.btn-primary {
    color: var(--text-color-2);
    background-color: var( --brand-color-1);
    border-color: var( --brand-color-1);
    &:hover {
        color: var(--text-color-2);
        background-color: var(--brand-color-3);
        border-color: var(--brand-color-3)
    }
}
.btn-outline-primary {
    color: var(--text-color-1);
    border-color: var(--brand-color-1);
    font-weight: 600!important;
    &:hover {
        color: var(--text-color-2);
        background-color: var( --brand-color-1);
        border-color: var( --brand-color-1);
    }
}
.btn-outline-secondary {
    color: var(--text-color-1-light-30);
    border-color: var(--text-color-1-light-30);
    &:hover {
        color: var(--text-color-2);
        background-color: var(--text-color-1-light-30);
        border-color: var(--text-color-1-light-30);
    }
}

.btn-info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border-radius: var(--border-radius-8);
    color: var(--text-color-4);
    border: 1px solid var(--brand-color-1);
    padding: 9px 15px;
    background: none;
    span {
        font-weight: 700;
        font-size: 19px;
        color: var(--brand-color-2);
        margin-right: 5px;
    }
}


// Inputs
input {    
    &:focus {
        border-color: var(--brand-color-1);
    }
}
.search_input {
    background: var(--background-color-2);
    border: 1px solid var(--border-color-1);
    border-radius: var(--border-radius-8);
    padding: 14px 20px;
    width: 100%;
    color: var(--text-color-1);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    @include placeholder(#A0AEC0);
    
    &:focus {
        border-color: var(--brand-color-1);
    }
    &.error {
        border-color: var(--text-color-error);
    }
}

.custom-checkbox {
    input[type=checkbox] {
        display: none;      
        
        & + label ,
        &:checked + label {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            position: relative;
            color: var(--text-color-1);
            cursor: pointer;
            display: inline-block;
            padding-left: 25px;
            white-space: nowrap;
            top: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 220px;

            &:before {
                content: '';
                height: 16px;
                width: 16px;
                border-radius: var(--border-radius-4);
                border: 1px solid var(--border-color-2);
                background-color: var(--background-color-2);
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &:checked  {           
            & +  label:after {
                content: ' \2713';
                position: absolute;
                top: 0px;
                left: 3px;
                font-size: 13px;
                color: var(--brand-color-1);
            }
            & + label::before {
                border-color: var(--brand-color-1);
            }
        }
    }
}
.custom-input-checkbox {  
    cursor: pointer; 
    input[type=checkbox] {
        display: none;
        & + .checkmark {
            display: block;
            position: relative;
            height: 16px;
            width: 16px;
            border-radius: var(--border-radius-4);
            border: 1px solid var(--border-color-2);
            background-color: var(--background-color-2);
            
            &:after {
                content: ' \2713';
                position: absolute;
                display: none;
                top: 0px;
                left: 3px;
                font-size: 13px;
                color: var(--brand-color-1);
                line-height: 16px;                            
            }
        }
        &:checked + .checkmark {
            border-color: var(--brand-color-1);
            &:after {
                display: block;
            }
        }
    }        
  
}


.link-primary {
    color: var(--brand-color-2);
    text-decoration: underline;
    background: none;
    @include transition(all, .15s, ease-in-out);
    &:hover {
        text-decoration: none;
    }
}

.custom_pagination {
    ul.pagination {
        display: -ms-flexbox;
        display: flex;
        padding-left: 0;
        list-style: none;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        li.page-item {
            padding: 0 5px;
            font-size: 14px;
            line-height: 16px;
            .page-link {
                cursor: pointer;
                color: var(--text-color-1);
                font-weight: 600;
                @include transition(all, .3s , ease-in-out);
                &:hover {
                    text-decoration: none;
                    color: var(--brand-color-1);
                    svg path {
                        fill: var(--brand-color-1);
                    }
                }
                
            }
            span {
                color: var(--text-color-5);
            }
            &.prev svg {
                transform: rotate(90deg)
            }
            &.next svg {
                transform: rotate(-90deg)
            }
            &.active {
                border: 1px solid var(--border-color-1);
                border-radius: var(--border-radius-6);
                min-width: 32px;
                min-width: 32px;
                text-align: center;
                padding: 2px 4px;

            }
            &.disabled {
                opacity: 0.3;
                cursor: default;
                pointer-events: none;
            }
        }
    }
}

.line {
    display: block;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--border-color-1);
}

.card {
    background-color: var(--background-color-2);
    border-radius: var(--border-radius-12);
    margin-bottom: 15px;
    border: 1px solid var(--border-color-1);
    .card_header {
        border-bottom: 1px solid var(--border-color-1);
        padding: 15px;
        .card_title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-color-1);
            text-transform: uppercase;
        }
    }
    .card_body {
        padding: 15px;
        .card_sub-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;            
            color: var(--text-color-1);
            margin-bottom: 15px;
        }
        .card_item {
            p {
                margin-bottom: 5px;
                font-family: 'Roboto';
                font-style: normal;
                &.number {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 33px;
                    color: var(--brand-color-2);
                }
                &.name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--text-color-1);
                }
                &.link {
                    a {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: var(--brand-color-2);                        
                    }
                }
            }
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}